@import "@/assets/style/_index.scss";






















































































.review-detail {
  padding: $padding;
  background-color: #fff;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .user {
      flex: 1 1 1%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      > .avatar {
        > .inner {
          width: 0.8rem;
          height: 0.8rem;
          background: transparent center no-repeat;
          background-size: cover;
          border-radius: 100vw;
        }

        margin-right: $margin-small;
      }
      > .info {
        > .review-time {
          color: $gray6;
          font-size: 80%;
        }
      }
    }
    > .extra {
      flex: 0 0 auto;
    }
  }
  > .body {
    padding: $padding-small 0;
    > .spec {
      display: inline-block;
      padding: $padding-small;
      border: 1px solid $color-second;
      border-radius: 10px;
      color: $color-second;
      font-size: 80%;
      line-height: 1.2;
      margin-top: $margin-small;
    }
    > .content {
      padding: $padding-small 0;
      word-break: break-all;
    }
  }
}
